<template>
  <v-card>
    <Datatable
      get_endpoint="activities"
      :header_items="headers"
      :sort_by="['id']"
      :sort_desc="[true]"
      user_friendly_identifier="firstname"
      type="entity.activity"
      :show_subject_type="
        item => {
          return true
        }
      "
      :item_on_click="logDetailsOnClick"
    >
      <template v-slot:[`item.subject_type`]="{ item }">
        {{
          item.subject_type
            ? $t(
                'entity.' +
                  item.subject_type.replace('App\\Models\\', '').charAt(0).toLowerCase() +
                  item.subject_type.replace('App\\Models\\', '').slice(1),
              )
            : ''
        }}
      </template>

      <template v-slot:[`item.event`]="{ item }">
        {{ $t('event.' + item.event) }}
      </template>
    </Datatable>

    <v-dialog v-model="logDialog" max-width="800">
      <v-card>
        <v-card-text>
          <template>
            <v-simple-table>
              <template>
                <tbody>
                  <tr>
                    <td>{{ $t('label.id') }}</td>
                    <td>{{ logDialogData.id }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('label.created_at') }}</td>
                    <td>{{ logDialogData.created_at }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('page.logs.causer') }}</td>
                    <td>{{ logDialogData.causer_id }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('label.event') }}</td>
                    <td>{{ logDialogData.event }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('page.logs.subject_type') }}</td>
                    <td>{{ logDialogData.subject_type }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('page.logs.subject_id') }}</td>
                    <td>{{ logDialogData.subject_id }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('page.logs.log_name') }}</td>
                    <td>{{ logDialogData.log_name }}</td>
                  </tr>

                  <tr>
                    <td>{{ $t('page.logs.new_data') }}</td>
                    <td>
                      <v-list>
                        <v-list-item
                          dense
                          class="px-0 mb-n2"
                          v-for="(value, field) in logDialogData.properties.attributes"
                          :key="field"
                        >
                          <span class="font-weight-medium me-2"> {{ $t('label.' + field) }}:</span>
                          <span class="text--secondary">{{ value === null ? 'NULL' : value }}</span>
                        </v-list-item>
                      </v-list>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('page.logs.old_data') }}</td>
                    <td>
                      <template v-if="logDialogData.properties.hasOwnProperty('old')">
                        <v-divider class="mb-3"></v-divider>
                        <v-list>
                          <v-list-item
                            dense
                            class="px-0 mb-n2"
                            v-for="(value, field) in logDialogData.properties.old"
                            :key="'old' + field"
                          >
                            <span class="font-weight-medium me-2"> {{ $t('label.' + field) }}:</span>
                            <span class="text--secondary">{{ value === null ? 'NULL' : value }}</span>
                          </v-list-item>
                        </v-list>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click="logDialog = false">
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Datatable from '@/components/Datatable/Datatable.vue'

export default {
  name: 'ProfileLogs',
  components: {
    Datatable,
  },
  data() {
    return {
      headers: [
        {
          text: 'label.created_at',
          value: 'created_at',
          search_type: 'date',
          data_format: 'date',
          sortable: true,
        },
        {
          text: 'page.logs.causer',
          value: 'causer.email',
        },
        {
          text: 'label.event',
          value: 'event',
          search_type: 'enum',
          enum_options: [
            {
              label: this.$t('event.created'),
              value: 'created',
            },
            {
              label: this.$t('event.updated'),
              value: 'updated',
            },
            {
              label: this.$t('event.deleted'),
              value: 'deleted',
            },
            {
              label: this.$t('event.started_personification'),
              value: 'started_personification',
            },
            {
              label: this.$t('event.stopped_personification'),
              value: 'stopped_personification',
            },
          ],
          sortable: true,
        },
        {
          text: 'page.logs.subject_type',
          value: 'subject_type',
          search_type: 'text',
          sortable: true,
        },
        {
          text: 'page.logs.subject_id',
          value: 'subject_id',
          search_type: 'number',
          sortable: true,
        },
        {
          text: 'page.logs.log_name',
          value: 'log_name',
          search_type: 'text',
          sortable: true,
        },
      ],
      logDialog: false,
      logDialogData: {
        subject_type: '',
        properties: {
          attributes: {},
          old: {},
        },
      },

      testarray: [2, 'c'],
    }
  },
  methods: {
    logDetailsOnClick(item) {
      this.logDialogData = item
      this.logDialog = true
    },
  },
}
</script>
