<template>
  <div style="display: contents">
    <template v-for="(route, i) in route_dependencies">
      <template v-if="showVueRoute(route)">
        <tr :key="i">
          <td>
            <div :style="{ marginLeft: calculateIndent() }" class="mr-4">
              {{ $t(route['title']) }}
            </div>
          </td>
        </tr>
        <template v-for="laravel_route in route['laravel-routes']">
          <tr
            v-if="laravel_route['optional'] && laravel_routes.includes(laravel_route['keyname'])"
            :key="laravel_route.keyname"
          >
            <td>
              <div :style="{ marginLeft: calculateIndent(1) }" class="mr-4">
                {{ $t('laravel_routes.' + laravel_route['keyname']) }}
              </div>
            </td>
          </tr>
        </template>
        <template v-if="route['children']">
          <profile-rights-menu
            :key="'child' + i"
            :indent="indent + 1"
            :route_dependencies_prop="route['children']"
            :laravel_routes_prop="laravel_routes"
            :vue_routes_prop="vue_routes"
          />
        </template>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ProfileRightsMenu',
  props: {
    id: {
      type: Number,
      required: false,
      default: 0,
    },
    indent: {
      type: Number,
      required: false,
      default: 0,
    },
    route_dependencies_prop: {
      type: Array,
      required: false,
    },
    laravel_routes_prop: {
      type: Array,
      required: false,
    },
    vue_routes_prop: {
      type: Array,
      required: false,
    },
  },

  mounted() {
    if (this.id > 0) {
      this.$http('/role_permissions/' + this.id).then(res => {
        this.route_dependencies = res.data.route_dependencies
        this.laravel_routes = res.data.permissions.laravel_permissions
        this.vue_routes = res.data.permissions.vue_permissions
      })
    } else {
      this.route_dependencies = this.route_dependencies_prop
      this.laravel_routes = this.laravel_routes_prop
      this.vue_routes = this.vue_routes_prop
    }
  },
  data: () => {
    return {
      route_dependencies: [],
      laravel_routes: [],
      vue_routes: [],
    }
  },
  methods: {
    calculateIndent(extra = 0) {
      const indent_pixels_amount = 40 * (this.indent + extra)
      return indent_pixels_amount + 'px'
    },
    showVueRoute(route) {
      if (route['mandatory']) {
        return false
      }

      if (route['vue-route']) {
        return this.vue_routes.includes(route['vue-route'])
      } else {
        //check if it has active children
        let show_route = false
        route.children.forEach(sub_route => {
          if (this.vue_routes.includes(sub_route['vue-route'])) {
            show_route = true
          }
        })
        return show_route
      }
    },
  },
}
</script>

<style lang="scss" scoped>
tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>

