<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="value" max-width="650px" @click:outside="$emit('input', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5" style="word-break: keep-all"> {{ $t('page.profile.edit_user_information') }}</v-card-title>

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="email.model"
                :error-messages="$t(email.error, { field: $t('label.email') })"
                :disabled="loading"
                outlined
                dense
                :label="$t('label.email')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="firstname.model"
                :error-messages="$t(firstname.error, { field: $t('label.firstname') })"
                :disabled="loading"
                outlined
                dense
                :label="$t('label.firstname')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="middlename.model"
                :error-messages="$t(middlename.error, { field: $t('label.middlename') })"
                :disabled="loading"
                outlined
                dense
                :label="$t('label.middlename')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="lastname.model"
                :error-messages="$t(lastname.error, { field: $t('label.lastname') })"
                :disabled="loading"
                outlined
                dense
                :label="$t('label.lastname')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="editUser"
                :loading="loading"
                :disabled="loading"
                >{{ $t('common.save') }}</v-btn
              >
              <v-btn outlined color="secondary" :disabled="loading" @click.prevent="$emit('input', false)">
                {{ $t('common.cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ProfileBioEdit',
  props: {
    value: Boolean,
    user_data: Object,
  },
  data: () => {
    return {
      loading: false,
      firstname: {
        model: '',
        error: '',
      },
      middlename: {
        model: '',
      },
      lastname: {
        model: '',
        error: '',
      },
      email: {
        model: '',
        error: '',
      },
    }
  },
  methods: {
    editUser: function () {
      this.$http
        .post('users/self', {
          email: this.email.model,
          firstname: this.firstname.model,
          middlename: this.middlename.model,
          lastname: this.lastname.model,
        })
        .then(res => {
          this.$store.commit('user/user', res.data)
          //clear all errors
          this.firstname.error = ''
          this.middlename.error = ''
          this.lastname.error = ''
          this.email.error = ''

          this.user_data.firstname = this.firstname.model
          this.user_data.middlename = this.middlename.model
          this.user_data.lastname = this.lastname.model
          this.user_data.email = this.email.model

          this.$emit('input', false)
        })
        .catch(error => {
          const errors = error.response.data.error
          this.email.error = errors.email ? errors.email[0] : ''
          this.firstname.error = errors.firstname ? errors.firstname[0] : ''
          this.lastname.error = errors.lastname ? errors.lastname[0] : ''
          this.roles.error = errors.roles ? errors.roles[0] : ''
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  watch: {
    value: function (new_value) {
      if (new_value) {
        const new_data = this.user_data
        this.firstname.model = new_data.firstname
        this.middlename.model = new_data.middlename
        this.lastname.model = new_data.lastname
        this.email.model = new_data.email
      }
    },
  },
}
</script>
