<template>
  <div>
    <v-card class="mb-7">
      <v-card-title>{{ $t('page.profile.change_password') }}</v-card-title>
      <v-card-subtitle>{{
        $store.getters['user/isAdmin'] ? $t('page.profile.change_password_not_available') : ''
      }}</v-card-subtitle>
      <v-card-text v-if="!$store.getters['user/isAdmin']">
        <v-form @submit.prevent>
          <v-row>
            <v-col md="8" cols="12">
              <v-text-field
                v-model="current_password.model"
                :disabled="loading"
                :label="$t('label.current_password')"
                :error-messages="$t(current_password.error, { field: $t('label.current_password'), x: 8 })"
                type="password"
                outlined
                dense
                hide-details="auto"
                class="mb-7"
              />
              <v-text-field
                v-model="password.model"
                :disabled="loading"
                :label="$t('label.password')"
                :error="!!repeat_password.error"
                type="password"
                dense
                outlined
                hide-details="auto"
                counter=""
                :hint="$t('common.min_x_characters', { x: 8 })"
              />
              <v-text-field
                v-model="repeat_password.model"
                :disabled="loading"
                :label="$t('label.repeat_password')"
                :error-messages="$t(repeat_password.error, { field: $t('label.password'), x: 8 })"
                type="password"
                dense
                outlined
                hide-details="auto"
                counter
                :hint="$t('common.min_x_characters', { x: 8 })"
                class="mb-2"
              />

              <v-btn @click="changePassword" type="submit" block color="primary" :disabled="loading" :loading="loading">
                {{ $t('page.create_password.change_password') }}
              </v-btn>
            </v-col>
            <v-col md="4" class="text-center hidden-sm-and-down" align-self="center">
              <v-icon size="100">{{ icons.lock }}</v-icon>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card v-if="two_factor_authentication_enabled">
      <v-card-title class="mb-1">{{ $t('page.profile.two_factor_authentication') }}</v-card-title>
      <v-card-subtitle class="font-weight-semibold">{{ $t('page.profile.authenticator_app') }}</v-card-subtitle>
      <v-card-text>
        {{ $t('page.profile.authenticator_app_description') }}
        <v-row class="my-1">
          <v-col cols="auto">
            <v-tooltip top nudge-top="10">
              <template v-slot:activator="{ on, attrs }">
                <a
                  href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
                  target="_blank"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-avatar size="50px" color="primary" class="v-avatar-light-bg primary--text">
                    <v-img src="@/assets/images/authenticators/microsoft.png"></v-img>
                  </v-avatar>
                </a>
              </template>
              <span>{{ $t('page.profile.more_info', { item: 'Microsoft Authenticator' }) }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="auto">
            <v-tooltip top nudge-top="10">
              <template v-slot:activator="{ on, attrs }">
                <a href="https://googleauthenticator.net/" target="_blank" v-bind="attrs" v-on="on">
                  <v-avatar size="50px" color="primary" class="v-avatar-light-bg primary--text">
                    <v-img src="@/assets/images/authenticators/google.png"></v-img>
                  </v-avatar>
                </a>
              </template>
              <span>{{ $t('page.profile.more_info', { item: 'Google Authenticator' }) }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="auto">
            <v-tooltip top nudge-top="10">
              <template v-slot:activator="{ on, attrs }">
                <a href="https://authy.com/download/" target="_blank" v-bind="attrs" v-on="on">
                  <v-avatar size="50px" color="primary" class="v-avatar-light-bg primary--text">
                    <v-img src="@/assets/images/authenticators/authy.png"></v-img>
                  </v-avatar>
                </a>
              </template>
              <span>{{ $t('page.profile.more_info', { item: 'Authy' }) }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- TODO: edit below so the right action button are dispalyed -->
      <v-card-actions>
        <div v-if="activatedMethods.includes('google-authenticator')">
          <v-btn @click="delete2FA('google-authenticator')" outlined color="error">{{ $t('common.delete') }}</v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="setFavorite2FA('google-authenticator')" icon v-bind="attrs" v-on="on" class="ml-2">
                <v-icon color="primary">{{
                  preferredMethod === 'google-authenticator' ? icons.star : icons.star_outline
                }}</v-icon>
              </v-btn>
            </template>
            <span>{{
              preferredMethod === 'google-authenticator'
                ? $t('page.profile.is_perferred')
                : $t('page.profile.set_as_perferred')
            }}</span>
          </v-tooltip>
        </div>
        <v-btn v-else @click="activateAuthenticator" class="primary">{{ $t('common.create') }}</v-btn>
      </v-card-actions>
      <v-card-text><v-divider></v-divider> </v-card-text>
      <v-card-subtitle class="font-weight-semibold">{{ $t('page.profile.yubikey') }}</v-card-subtitle>
      <v-card-text>
        {{ $t('page.profile.yubikey_description') }}
      </v-card-text>
      <v-card-actions>
        <div v-if="activatedMethods.includes('yubikey')">
          <v-btn @click="delete2FA('yubikey')" outlined color="error">{{ $t('common.delete') }}</v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="setFavorite2FA('yubikey')" icon v-bind="attrs" v-on="on" class="ml-2">
                <v-icon color="primary">{{ preferredMethod === 'yubikey' ? icons.star : icons.star_outline }}</v-icon>
              </v-btn>
            </template>
            <span>{{
              preferredMethod === 'yubikey' ? $t('page.profile.is_perferred') : $t('page.profile.set_as_perferred')
            }}</span>
          </v-tooltip>
        </div>
        <v-btn v-else @click="activateYubikey" class="primary">{{ $t('common.create') }}</v-btn>
      </v-card-actions>
      <v-card-text><v-divider></v-divider> </v-card-text>
      <v-card-subtitle class="font-weight-semibold">{{ $t('page.profile.secondary_email') }}</v-card-subtitle>
      <v-card-text>
        {{ $t('page.profile.secondary_email_description') }}
        <v-row class="mt-2">
          <v-col>
            <v-text-field
              v-model="secondary_email.model"
              @keyup.enter="editSecondaryEmail"
              :error-messages="$t(secondary_email.error)"
              :label="$t('label.email')"
              :disabled="!editing_secondary_email"
              outlined
              dense
              hide-details="auto"
              ref="secondary_email"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn :loading="loading" color="primary" @click="editSecondaryEmail" class="">
              <v-icon class="mr-1">{{ editing_secondary_email ? icons.save : icons.edit }}</v-icon>
              {{ editing_secondary_email ? $t('common.save') : $t('common.edit') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="create_two_factor_dialog" max-width="650">
      <v-card class="py-8">
        <v-card-title class="justify-center text-h5 px-5"> {{ $t('page.twofactor.activate_twofactor') }} </v-card-title>
        <v-card-text class="text-center mt-n2 px-5">
          {{
            create_two_factor_method === 'google-authenticator'
              ? $t('page.twofactor.activate_authenticator_description')
              : $t('page.twofactor.activate_yubikey_description')
          }}
        </v-card-text>
        <div class="my-8" v-if="create_two_factor_method === 'google-authenticator'">
          <div v-if="!qr_string" style="height: 280px"></div>
          <qrcode-vue v-else class="text-center" :value="qr_string" size="280" level="M" />
        </div>
        <v-form @submit.prevent>
          <v-card-text>
            <v-text-field
              v-model="code.model"
              :disabled="loading"
              :label="$t('label.code')"
              :error-messages="$t(code.error, { field: $t('label.code') })"
              outlined
              hide-details="auto"
              class="mb-6"
              :type="create_two_factor_method === 'yubikey' ? 'password' : ''"
            />
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-row justify="center">
              <v-col cols="auto">
                <v-btn @click="activateTwofactor" type="submit" :disabled="loading" class="primary">{{
                  $t('common.activate')
                }}</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn @click="create_two_factor_dialog = false" :disabled="loading" outlined color="error">{{
                  $t('common.cancel')
                }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import themeConfig from '@themeConfig'
import Vue from 'vue'
import QrcodeVue from 'qrcode.vue' //eslint-disable-line
import { mdiStar, mdiStarOutline, mdiLockOutline, mdiPencil, mdiContentSaveOutline } from '@mdi/js'

export default {
  name: 'ProfileSecurity',
  components: {
    QrcodeVue,
  },
  beforeMount() {
    if (this.two_factor_authentication_enabled) {
      this.$http('2fa/secret')
        .then(res => {
          this.qr_string = res.data.qr_string
          this.secret = res.data.secret
        })
        .catch(() => {
          this.$router.back()
        })

      this.secondary_email.model = this.$store.state.user.secondary_email
    }
  },
  data: () => {
    return {
      two_factor_authentication_enabled: (process.env.VUE_APP_2FA_ENABLED != "false"),

      create_two_factor_dialog: false,
      create_two_factor_method: 'google-authenticator',
      editing_secondary_email: false,

      qr_string: undefined,
      secret: undefined,

      code: {
        model: '',
        error: '',
      },
      loading: false,

      app_logo: themeConfig.app.logo,

      icons: {
        star: mdiStar,
        star_outline: mdiStarOutline,
        lock: mdiLockOutline,
        edit: mdiPencil,
        save: mdiContentSaveOutline,
      },

      //password reset information
      isPasswordVisible: false,
      current_password: {
        model: '',
        error: '',
      },
      password: {
        model: '',
      },
      repeat_password: {
        model: '',
        error: '',
      },
      secondary_email: {
        model: '',
        error: '',
      },
    }
  },
  computed: {
    activatedMethods: function () {
      return this.$store.state.user.activated_two_factor_methods
    },
    preferredMethod: function () {
      return this.$store.state.user.preferred_two_factor_authentication_method
    },
  },
  methods: {
    activateAuthenticator: function () {
      this.create_two_factor_method = 'google-authenticator'
      this.create_two_factor_dialog = true
    },
    activateYubikey: function () {
      this.create_two_factor_method = 'yubikey'
      this.create_two_factor_dialog = true
    },
    activateTwofactor: function () {
      if (!this.code.model) {
        this.code.error = 'validation.required'
        return
      }

      this.code.error = ''
      this.loading = true

      this.$http
        .post('2fa/activate', {
          code: this.code.model,
          method: this.create_two_factor_method,
          secret: this.secret,
        })
        .then(res => {
          this.$store.commit('user/user', res.data.user)
          this.$store.commit('notification/notify', { text: 'notification.2fa_activated' })
          this.create_two_factor_dialog = false
        })
        .catch(err => {
          this.code.error = err.response.data.message
        })
        .finally(() => {
          this.loading = false
        })
    },
    delete2FA: function (method) {
      this.$http.post('2fa/delete?method=' + method).then(res => {
        this.$store.commit('user/user', res.data.user)
        this.$store.commit('notification/notify', { text: 'notification.2fa_deleted' })

        if (!this.$store.state.user.two_factor_authenticated) {
          //when all 2fa methods are deleted the user should be redirected to the activation page
          this.$router.push('/twofactor/activate')
        }
      })
    },
    setFavorite2FA: function (method) {
      if (this.preferredMethod !== method) {
        //only make the request if its not already set
        this.$http.post('2fa/favorite?method=' + method).then(res => {
          this.$store.commit('user/user', res.data.user)
        })
      }
    },
    changePassword: function () {
      this.loading = true

      if (this.password.model !== this.repeat_password.model) {
        this.repeat_password.error = 'page.create_password.passwords_dont_match'
        this.loading = false
        return
      }

      this.$http
        .post('users/self/change_password', {
          current_password: this.current_password.model,
          password: this.password.model,
        })
        .then(() => {
          this.repeat_password.error = ''
          this.current_password.error = ''
          this.current_password.model = ''
          this.password.model = ''
          this.repeat_password.model = ''
          this.$store.commit('notification/notify', {
            text: 'notification.saved',
          })
        })
        .catch(err => {
          const errors = err.response.data.error
          if (errors) {
            this.repeat_password.error = errors.password ? errors.password[0] : ''
            this.current_password.error = errors.current_password ? errors.current_password[0] : ''
          } else {
            this.current_password.error = 'error.incorrect_password'
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    editSecondaryEmail() {
      if (this.editing_secondary_email) {
        this.secondary_email.error = ''
        this.loading = true
        this.$http
          .post('users/self', { secondary_email: this.secondary_email.model })
          .then(() => {
            this.editing_secondary_email = false
          })
          .catch(err => {
            if (err.response.data.error) {
              let errors = err.response.data.error
              this.secondary_email.error = errors.secondary_email[0]
            }
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.editing_secondary_email = true
        Vue.nextTick(() => {
          this.$refs.secondary_email.focus()
        })
      }
    },
  },
}
</script>
