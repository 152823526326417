<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <profile-bio-overview />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="tab_model" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ $t(tab.title) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items id="user-tabs-content" v-model="tab_model" class="mt-5 pa-1">
          <v-tab-item>
            <profile-security />
          </v-tab-item>

          <v-tab-item>
            <profile-logs />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ProfileSecurity from './tabs/Security.vue'
import ProfileLogs from './tabs/Logs.vue'
import ProfileBioOverview from './bio/Overview.vue'
import { mdiPost, mdiLockOutline } from '@mdi/js'

export default {
  name: 'Profile',
  components: {
    ProfileSecurity,
    ProfileLogs,
    ProfileBioOverview,
  },

  data: () => {
    return {
      tab_model: false,

      tabs: [
        { icon: mdiLockOutline, title: 'page.profile.security' },
        { icon: mdiPost, title: 'page.profile.logs' },
      ],
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>