<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <input ref="uploader" type="file" class="d-none" @change="inputChanged" />
          <v-menu nudge-right="120">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-bind="attrs"
                v-on="on"
                :color="user.avatar ? '' : 'primary'"
                :class="user.avatar ? '' : 'v-avatar-light-bg primary--text'"
                size="120"
                rounded
                class="mb-4"
                style="cursor: pointer"
              >
                <v-img v-if="user.avatar" :src="user.avatar"></v-img>
              </v-avatar>
            </template>
            <v-list>
              <v-list-item @click="editAvatarOnClick">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ mdiPencil }}
                  </v-icon>
                  <span>{{ $t('common.edit') }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteAvatarOnClick">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ mdiDelete }}
                  </v-icon>
                  <span>{{ $t('common.delete') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <span class="mb-3">{{ $store.getters['user/fullName'] }}</span>

          <v-row justify="center">
            <v-col class="px-1 py-1" v-for="role in $store.state.user.roles" :key="role.id" cols="auto">
              <v-menu offset-y nudge-bottom="5">
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-chip
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        small
                        :color="role.id == $store.state.user.current_role_id ? 'primary' : ''"
                        >{{ role.translations[$i18n.locale].name }}</v-chip
                      >
                    </template>
                    <span>{{ $t('page.profile.tooltip_role') }}</span>
                  </v-tooltip>
                </template>
                <v-card>
                  <v-card-subtitle
                    >{{
                      $t('page.profile.rol_permissions_description', { role: role.translations[$i18n.locale].name })
                    }}
                  </v-card-subtitle>
                  <v-card-text>
                    <v-divider />
                    <table class="permissions-datatable">
                      <profile-rights-menu :id="role.id" />
                    </table>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">{{ $t('page.profile.details') }}</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"> {{ $t('label.email') }}:</span>
              <span class="text--secondary">{{ user.email }}</span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"> {{ $t('label.firstname') }}:</span>
              <span class="text--secondary">{{ user.firstname }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('label.middlename') }}:</span>
              <span class="text--secondary">{{ user.middlename }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('label.lastname') }}:</span>
              <span class="text--secondary">{{ user.lastname }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn color="primary" class="me-3" @click="bio_dialog = !bio_dialog">{{ $t('common.edit') }}</v-btn>
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <profile-bio-edit v-model="bio_dialog" :user_data.sync="user"></profile-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import ProfileBioEdit from './Edit.vue'
import ProfileRightsMenu from './RightsMenu.vue'
import { mdiPencil, mdiDelete } from '@mdi/js'

export default {
  name: 'ProfileBioOverview',
  components: {
    ProfileBioEdit,
    ProfileRightsMenu,
  },
  beforeMount() {
    
  },
  mounted() {
    this.user = JSON.parse(JSON.stringify(this.$store.state.user))
  },
  data: () => {
    return {
      user: {
        roles: {}
      },
      bio_dialog: false,
      mdiPencil,
      mdiDelete,
    }
  },
  methods: {
    editAvatarOnClick() {
      this.$refs.uploader.click()
    },
    inputChanged() {
      if (this.$refs.uploader.files[0]) {
        var formData = new FormData()
        formData.append('avatar', this.$refs.uploader.files[0])
        this.$http
          .post('users/self/avatar', formData)
          .then(res => {
            this.$store.commit('user/user', res.data)
            this.user.avatar = this.$store.state.user.avatar
          })
          .finally(() => {
            this.$refs.uploader.value = null
          })
      }
    },
    deleteAvatarOnClick() {
      this.$http.delete('users/self/avatar').then(res => {
        this.$store.commit('user/user', res.data)
        //When editing yourself the jwt will be refreshed so it contains the right information
        this.user.avatar = this.$store.state.user.avatar
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.permissions-datatable {
  border-collapse: collapse !important;
}
</style>

